import { Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { UniversalButton } from '../../../../components';
import routeConfiguration from '../../../../routing/routeConfiguration';
import { pathByRouteName } from '../../../../util/routes';
import { CarouselItemBackground } from './CarouselItemBackground';

export const HeroCarouselSlide = (props) => {
  const { id, src, srcSmall, color, to , buttonType = 'contrast', routeParams, isFirstSlide} = props;

  const intl = useIntl();
  const history = useHistory();

  const handleClick = () => {
    const path = pathByRouteName(to, routeConfiguration(), routeParams);
    history.push(path);
  };

  return (
    <Stack
      width="100%"
      height="100%"
      justifyContent={{ xs: 'flex-end', sm: 'center' }}
      position="relative"
      overflow="hidden"
      px={{ xs: 2, sm: 10, md: 14 }}
      pb={{ xs: 6, sm: 0 }}
      sx={{
        '& button': { width: { xs: '100%', sm: 248 } },
        mx: 0,
        '@media (max-width: 1439px)': { mx: 0 },
      }}
    >
      <CarouselItemBackground src={src} alt={id} display={{ xs: 'none', sm: 'block' }} />
      <CarouselItemBackground src={srcSmall} alt={id} display={{ xs: 'block', sm: 'none' }} />
      <Typography
        variant={isFirstSlide ? 'h1' : 'h2'}
        color={color}
        fontSize={{ xs: 30, md: 52 }}
        fontWeight={600}
        mb={2}
        maxWidth={{ xs: '90%', sm: '60%' }}
      >
        {intl.formatMessage({ id: `landing_page.section_hero.${id}.title` })}
      </Typography>
      <Typography
        variant="body1"
        color={color}
        fontSize={{ xs: 16, md: 20 }}
        mb={3}
        maxWidth={{ xs: '90%', sm: '60%' }}
      >
        {intl.formatMessage({ id: `landing_page.section_hero.${id}.content` })}
      </Typography>
      <UniversalButton type={buttonType} onClick={handleClick}>
        {intl.formatMessage({ id: `landing_page.section_hero.${id}.button` })}
      </UniversalButton>
    </Stack>
  );
};
