import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSpringCarousel } from 'react-spring-carousel';
import { CarouselArrowButton } from './CarouselArrowButton';
import { CarouselNavigationBubbles } from './CarouselNavigationBubbles';
import { HeroCarouselSlide } from './HeroCarouselSlide';

export const HeroCarousel = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { carouselFragment, slideToPrevItem, slideToNextItem, slideToItem, getCurrentActiveItem } = useSpringCarousel({
    withLoop: true,
    items: items.map(slide => ({
      id: slide.id,
      renderItem: <HeroCarouselSlide {...slide} isFirstSlide={slide.id === items[0].id} />,
    })),
  });

  useEffect(() => {
    const interval = setInterval(() => {
      slideToNextItem();
      setActiveIndex(getCurrentActiveItem().index);
    }, 5000);

    return () => clearInterval(interval);
  }, [slideToNextItem]);

  const handleSlideToItem = index => {
    slideToItem(index);
    setActiveIndex(index);
  };

  const handleSlideToNextItem = () => {
    slideToNextItem();
    setActiveIndex(getCurrentActiveItem().index);
  };

  const handleSlideToPrevItem = () => {
    slideToPrevItem();
    setActiveIndex(getCurrentActiveItem().index);
  };

  return (
    <Box width="100%" height="100%" position="relative">
      {carouselFragment}
      <CarouselArrowButton direction="left" onClick={handleSlideToPrevItem} />
      <CarouselArrowButton direction="right" onClick={handleSlideToNextItem} />
      <CarouselNavigationBubbles count={items.length} activeIndex={activeIndex} onClick={handleSlideToItem} />
    </Box>
  );
};
